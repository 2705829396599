import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}

const Addcasecopy = (props) => {
  
  const { rechargeAmt, payerName, payerEmail, payerMobile } = useParams();
  const [qrCodeResponse, setQrCodeResponse] = useState(null);

  console.log("amt", rechargeAmt);
  console.log("Email", payerEmail);
  console.log("recharge amount ============>" + rechargeAmt);
  
  const [caseId, setCaseId] = useState(null);
  const [isbn, setIsbn] = useState(null);
  const [PaymentLive, setPaymentLive] = useState(null);

  useEffect(() => {
    const urlPath = window.location.pathname;
    const pathSegments = urlPath.split("/");
    console.log(pathSegments);

    if (pathSegments.length >= 5) {
      setCaseId(pathSegments[2]);
      setIsbn(pathSegments[5]);
    }
  }, []);


  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    setPaymentLive(data.upiGetway);
  };

  useEffect(() => {
    fetchData();
  }, []);


  const handleUPILivePaymant = () => {
    const amount = caseId;
    const mobile = isbn;
    const redirectUrl = `https://payment.ludobullet.com/index.php?&amount=${amount}&mobile=${mobile}`;
    window.location.href = redirectUrl;
  }
  
  const handleManualPaymant = () => {
    const amount = caseId;
    const mobile = isbn;
    const redirectUrl = `/Manual_Payment?&amount=${amount}&mobile=${mobile}`;
    window.location.href = redirectUrl;
  };


  const handleButtonClick = () => {
    if (PaymentLive == true) {
      handleUPILivePaymant();
    } else if (PaymentLive == false) {
      handleManualPaymant();
    } else {
      console.error("not support");
    }
  };






  // const handleButtonClick = () => {
  //   const amount = caseId;
  //   const mobile = isbn;
  //   const redirectUrl = `/Manual_Payment?&amount=${amount}&mobile=${mobile}`;
  //   window.location.href = redirectUrl;
  // };

  return (
    <div className="container-fluid" style={{ marginTop: "100px" }}>
      <div
        className="d-flex align-items-center"
        style={{
          backgroundColor: "#fafafa",
          border: "1px solid #e0e0e0",
          borderRadius: "7px",
        }}
      >
        <div
          className="d-flex align-items-center"
          style={{
            height: "60px",
            display: "flex",
            textAlign: "center",
          }}
        >
          <img
            width="50px"
            src="/Images/UPI.png"
            alt=""
            style={{
              marginLeft: "7px",
              paddingBottom: "10px",
              paddingLeft: "3px",
              paddingTop: "5px",
            }}
          />
        </div>

        <div className="d-flex justify-content-center flex-column ml-4">
          <div id="renderSabPaisa" className="jss30"></div>
          <div className="jss30">
            <button
              style={{
                borderRadius: "8px",
                border: "none",
                backgroundColor: "none",
                fontWeight: "bold",
                fontSize: "20px",
              }}
              onClick={handleButtonClick}
            >
              Proceed To Pay
            </button>
          </div>
          <div className="jss31"></div>
        </div>
      </div>

      <div style={{ margin: "20px 8px" }}>
        <span style={{ color: "red", fontSize: "14px" }}>
          जब आप पेमेंट ऐड कर रहे हो तो वहाँ जब तक ऑटोमैटिक पेमेंट
          <br />
          ऐड नहीं हो जाये तब तक ना बैक आये और ना ही refresh करे ।
        </span>
      </div>
    </div>
  );
};

export default Addcasecopy;
